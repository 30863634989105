@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;700&display=swap');
body{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding:0;
    margin:0;
    height:100vh;
    background-color: #404040;
    font-family: 'Oswald', sans-serif;
}

.hidden{
    visibility: hidden;
}

strong{
    font-size: 2em;
}

#info{
    position: absolute;
    bottom: 80px;
    left:16px;
    background-color: #808080;
    padding:6px 16px;
    border-radius: 90px;
    border: 3px solid #202020;
    color: #202020;
    font-weight: 700;
    font-size: 1.2em;
    user-select: none;
    cursor: pointer;
}

#infoPanel{
    position: absolute;
    bottom: 140px;
    left:16px;
    background-color: #808080;
    padding:4px 8px;
    font-size: 0.75em;
    border-radius: 4px;
    border: 3px solid #202020;
    color: #202020;
    max-width: 350px;
}

#content{
    
    display:flex;
    width:100vw;
    flex: none;
    align-self: stretch;
    flex-grow: 1;
}

#navContainer{
    position:absolute;
    bottom:0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0px;
    background-color: #d0d0d0;
    height:60px;
    width:100%;
    flex: none;
    
    align-self: stretch;
    flex-grow: 0;
}

#mainNav{
    position: relative;
    width: 100%;
    height: 100%;
    background: #6f6f6f;
    align-items:stretch;
    display:flex;
    overflow:scroll;
}

.navItem{
    cursor: pointer; 
    text-align: center;
    min-width:100px;
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0px;
    width:100%;
    background-color: #363333;
    margin: 1px 0;
    padding:1px;
}
.navItem:not(.active):hover{
    background-color: #474444;
}
.active{
    background-color: #efefef;
    color:#202020;
}
.active .itemTitle{
    color:#404040;
}

.itemTitle{
    color:#efefef;
    font-size:.7em;
    padding:0.5px;
    line-height: 1;
    position:relative;
    z-index: 10;
}
.itemDate{
    color:#808080;
    font-size:0.5em;
    padding:0;
    position:relative;
    z-index: 8;
}
