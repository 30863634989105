@import "https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;700&display=swap";
body {
  height: 100vh;
  background-color: #404040;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  font-family: Oswald, sans-serif;
  display: flex;
}

.hidden {
  visibility: hidden;
}

strong {
  font-size: 2em;
}

#info {
  color: #202020;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  background-color: gray;
  border: 3px solid #202020;
  border-radius: 90px;
  padding: 6px 16px;
  font-size: 1.2em;
  font-weight: 700;
  position: absolute;
  bottom: 80px;
  left: 16px;
}

#infoPanel {
  color: #202020;
  max-width: 350px;
  background-color: gray;
  border: 3px solid #202020;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: .75em;
  position: absolute;
  bottom: 140px;
  left: 16px;
}

#content {
  width: 100vw;
  flex: 1 0 auto;
  align-self: stretch;
  display: flex;
}

#navContainer {
  height: 60px;
  width: 100%;
  background-color: #d0d0d0;
  flex-direction: column;
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-start;
  padding: 0;
  display: flex;
  position: absolute;
  bottom: 0;
}

#mainNav {
  width: 100%;
  height: 100%;
  background: #6f6f6f;
  align-items: stretch;
  display: flex;
  position: relative;
  overflow: scroll;
}

.navItem {
  cursor: pointer;
  text-align: center;
  min-width: 100px;
  width: 100%;
  background-color: #363333;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0;
  margin: 1px 0;
  padding: 1px;
  display: flex;
}

.navItem:not(.active):hover {
  background-color: #474444;
}

.active {
  color: #202020;
  background-color: #efefef;
}

.active .itemTitle {
  color: #404040;
}

.itemTitle {
  color: #efefef;
  z-index: 10;
  padding: .5px;
  font-size: .7em;
  line-height: 1;
  position: relative;
}

.itemDate {
  color: gray;
  z-index: 8;
  padding: 0;
  font-size: .5em;
  position: relative;
}

/*# sourceMappingURL=index.1c212234.css.map */
